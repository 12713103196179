<template>
  <div>
    <ubicacionesList />
  </div>
</template>

<script>
const ubicacionesList = () => import('@/layouts/components/Ubicaciones/UbicacionesList.vue')
// import { mapGetters } from 'vuex'

export default {
  components: {
    ubicacionesList,
  },
  computed: {
    // ...mapGetters({ usuarios }),
  },
  created() {
    // Consultar autenticación del
  },
}
</script>

<style>

</style>
